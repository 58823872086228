import { useEffect, useMemo, useState } from "react";
import { bool, func, string } from "prop-types";
import { observer } from "mobx-react";
import { useTranslation } from "react-i18next";
import { StructureModel, unzipStructure } from "project-structure";
import { useActiveProjectStore, useStores } from "@hooks";
import {
  getProjectVersionStructureQuery,
  storeProjectBudgetTrackingQuery,
  fetchProjectBudgetTrackingQuery,
  fetchProjectIssueTypesQuery,
  fetchCompanyJiraProjects,
  storeIssuesBudgetTrackingQuery, forceProjectSaveQuery,
} from "@query";
import { Alert, Dialog } from "@components";
import { CreationProgress } from "@dialogs";
import { IssueMappingForm, JiraExportForm } from "@forms";
import {
  CircularProgress,
  Collapse,
  Grid,
} from "@material-ui/core";

export const JiraExportModal = observer(({
  onClose,
  uuid,
  projectJiraName,
  onOpenInfo
}) => {
  const { t } = useTranslation();
  const { userStore } = useStores();
  const activeProjects = useActiveProjectStore();

  const [isLoading, setLoading] = useState(true);
  const [showProgress, setShowProgress] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [showModal, setShowModal] = useState(false);
  const [projectId, setProjectId] = useState(null);
  const [exportedProjectId, setExportedProjectId] = useState(null);
  const [timeTrackingAlertOpen, setTimeTrackingAlert] = useState(false);
  const [savedIssueMappingData, setSavedIssueMappingData] = useState(null);
  
  const [savedJiraProject, setSavedJiraProject] = useState();
  const [savedJiraVersion, setSavedJiraVersion] = useState();
  
  const [jiraProjects, setJiraProjects] = useState([]);
  const [selectedJiraProject, setSelectedJiraProject] = useState();
  const [issueTypes, setIssueTypes] = useState([]);
  const [versions, setVersions] = useState([]);
  const [selectedVersionStructure, setStructure] = useState();

  const projectName = useMemo(
    () =>
      projectJiraName ||
      activeProjects.projects.find((p) => p.uuid === uuid)?.name ||
      "",
    []
  );

  useEffect(() => {
    (async () => {
      await forceProjectSaveQuery(uuid);
      const apropoProj = await getProjectVersionStructureQuery(uuid, true);
      setVersions(apropoProj.results.versions);
      setProjectId(apropoProj.results.id);
      
      const res = await fetchCompanyJiraProjects(userStore.data.companyId);
      setJiraProjects(res.data);
      
      if (projectJiraName) {
        const jiraProj = await fetchProjectBudgetTrackingQuery(
          apropoProj.results.id
        );
        if(jiraProj) {
          setSavedJiraVersion(jiraProj.version);
          setSavedJiraProject({
            id: jiraProj.jiraId.toString(),
            name: jiraProj.name,
          });
        }
      }

      setLoading(false);
    })();
  }, []);

  const saveProject = async ({ project: { name, id }, version }) => {
    setShowProgress(true);
    try {
      const res = await storeProjectBudgetTrackingQuery(projectId, version, id);
      setExportedProjectId(res.id);
      
      const issueTypesScheme = await fetchProjectIssueTypesQuery(projectId);
      
      await forceProjectSaveQuery(uuid);
      const apropoProj = await getProjectVersionStructureQuery(uuid, true);
      const versionObj = apropoProj.results.versions.find(
        ({ key }) => key === version
      );
      const structure = versionObj
        ? StructureModel.create(
            JSON.parse(unzipStructure(versionObj.structure))
          )
        : null;

      setIssueTypes(issueTypesScheme.data);
      setSelectedJiraProject({ id, name });
      setStructure(structure);
      setShowProgress(false);
    } catch (err) {
      if (err.response && err.response.data && err.response.data.error) {
        setErrorMessage(err.response.data.error);
      } else if (
        err.response &&
        err.response.data &&
        err.response.data.errors
      ) {
        if (err.response.status === 422) {
          err.response.data.errors?.key?.length === 1
            ? setErrorMessage(t(err.response.data.errors.key))
            : null;
          err.response.data.errors?.name?.length === 1
            ? setErrorMessage(t(err.response.data.errors.name))
            : null;
        }
      } else if (err.message) setErrorMessage(err.message);
    }
  };

  const saveProjectIssueMapping = async ({
    mergeWorkTypes,
    workTypes,
    mappingSections,
    ...maps
  }) => {
    if (!selectedVersionStructure)
      return;
    
    const mapping = {};
    for (let i = 0; i <= 3; i++) {
      if (maps[`level${i}Used`] && maps[`level${i}Map`])
        mapping[i] = maps[`level${i}Map`];
    }
    const data = {
      projectId: exportedProjectId,
      mergeWorkTypes,
      mapping,
      isTimeTracking: Boolean(workTypes.length),
      mappingSections,
      workTypes: workTypes.reduce((t, wT) => {
        const name = selectedVersionStructure?.workTypes.find(
          (w) => w.id === Number(wT)
        )?.name;
        if (!name) return t;
        return { ...t, [name]: Number(wT) };
      }, {}),
    };
    
    await commitIssueMapping(data);
  };
  
  const saveIssueMappingWithoutEstimates = async () => {
    if(!savedIssueMappingData) return;
    
    await commitIssueMapping({
      ...savedIssueMappingData,
      isTimeTracking: false,
    });
    setSavedIssueMappingData(null);
  }
  
  const commitIssueMapping = async (data) => {
    if (!selectedVersionStructure)
      return;
    setShowProgress(true);
    try {
      
      await storeIssuesBudgetTrackingQuery(data);
      setShowProgress(false);
      activeProjects.updateLocalProject(String(uuid), {
        btExported: true,
      });
      onOpenInfo();
      onClose();
    } catch (err) {
      if(err?.response?.data?.message === "errors.bt.time_tracking.required") {
        setShowProgress(false);
        setSavedIssueMappingData(data);
        setTimeTrackingAlert(true);
        return {
          errors: [{ field: "isTimeTracking"}]
        };
      } else if (err.response && err.response.data && err.response.data.error) {
        setErrorMessage(err.response.data.error);
      } else if (err.message) setErrorMessage(err.message);
    }
  }

  const cancelSaving = () => {
    setErrorMessage("");
    setShowProgress(false);
  };

  return (
    <Dialog
      id="newProject"
      open
      onClose={onClose}
      actionsClass="btProjectForm"
      containerId="btProjectForm"
      containerClass="btProjectForm p-0"
      width={600}
      title={t("views.bt.select_project")}
    >
      {selectedJiraProject && (
        <h2 className="mb-4 px-6 pt-6 text-xl">{selectedJiraProject.name}</h2>
      )}
      {
        isLoading
          ? <Grid item container justifyContent="center" className="my-4">
              <CircularProgress />
            </Grid>
          : <>
            <Collapse
              in={!selectedJiraProject && projectName && !!versions.length}
              mountOnEnter
              unmountOnExit
            >
              <JiraExportForm
                onSubmit={saveProject}
                projects={jiraProjects}
                projectVersions={versions}
                defaultVersion={savedJiraVersion || versions[0].key}
                defaultProject={savedJiraProject}
              />
            </Collapse>
            <Collapse
              in={Boolean(selectedJiraProject && selectedVersionStructure)}
              mountOnEnter
              unmountOnExit
            >
              <IssueMappingForm
                issueTypes={issueTypes}
                onSubmit={saveProjectIssueMapping}
                selectedVersion={selectedVersionStructure}
                projectViewLevel={
                  selectedVersionStructure?.settings.viewLevel || 3
                }
              />
            </Collapse>
          </>
      }
      <CreationProgress
        open={showProgress}
        onCancel={cancelSaving}
        errorMessage={errorMessage}
      />
      <Alert
        isOpen={timeTrackingAlertOpen}
        onAccept={saveIssueMappingWithoutEstimates}
        onCancel={() => setTimeTrackingAlert(false)}
        acceptText={t("views.bt.time_tracking_error.accept")}
        cancelText={t("views.bt.time_tracking_error.cancel")}
        title={t("views.bt.time_tracking_error.title")}
      >
        {t("views.bt.time_tracking_error.content")}
        <br />
        {t("views.bt.time_tracking_error.content2")}
      </Alert>
      <Alert
        isOpen={showModal}
        title={t("views.new_project.finish")}
        acceptText={t("views.project.create")}
        onCancel={() => setShowModal(false)}
        onAccept={saveProject}
      />
    </Dialog>
  );
});

JiraExportModal.propTypes = {
  onClose: func.isRequired,
  uuid: string.isRequired,
  projectJiraName: string,
  onOpenInfo: bool,
};
