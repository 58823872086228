
import { APIMethod, fetchData } from "@client";

export async function sendRecordingPartQuery(
  projectUuid,
  shareSettings
) {
  await fetchData(`/project/set/${projectUuid}`, APIMethod.PATCH, {
    data: { settings: JSON.stringify(shareSettings) },
  });
}
