import { Fragment } from "react";
import { bool, func, number, object, oneOfType, string } from "prop-types";
import { useStructureStore } from "@hooks";
import { Grid } from "@material-ui/core";
import { EditorTitleRow } from "./EditorTitleRow";
import { EditorRow } from "./EditorRow/EditorRow";
import { observer } from "mobx-react";

export const EditorContainer = observer(({
  selectedWorkType,
  selectedResource,
  section,
  filterWorkTypes,
  noTeamTogetherSwitch=true,
  ...rowProps
}) => {
  const { settings, workTypes, workTypesHaveResources } = useStructureStore();
  const { hasTeamMembersCol, timeModifier, useMinMax } = settings;
  
  const minStartMonth = section ? (section.timelineStartMonth || 0) + 1 : 1;
  
  return (
    <Grid item container spacing={1}>
      <EditorTitleRow
        useTeamTogetherSwitch={!noTeamTogetherSwitch && workTypesHaveResources}
        {...rowProps}
      />
      {workTypes
        .filter((workType) => (
          (!filterWorkTypes
            || filterWorkTypes(workType)
          ) &&
          (!section
          || (
            section.workTypes.find(wT => wT.id === workType.id)
            && !workType.areHoursOverwritten
          ))
        ))
        .map((workType) => {
          const sectionElement = section?.getWorkTypeById(workType.id);
          const disabled =  workType.hasOnlyCost || (
            sectionElement ? sectionElement.hasPercent : workType.usesTimelinePercent
        )
          return <Fragment key={`wT${workType.id}`}>
            <EditorRow
              element={workType}
              sectionElement={sectionElement}
              hasChildren={hasTeamMembersCol && workType.hasResources}
              timeModifier={timeModifier}
              useMinMax={useMinMax}
              workTypeSettings
              autoFocus={selectedWorkType === workType.id}
              disabled={disabled}
              useTeamTogetherSwitch={!noTeamTogetherSwitch && workTypesHaveResources}
              minStartMonth={minStartMonth}
              {...rowProps}
            />
            {hasTeamMembersCol &&
              workType.resources?.map(
                (resource) => (
                  <EditorRow
                    key={`res${resource.id}`}
                    element={resource}
                    sectionElement={sectionElement?.getResourceById(resource.id)}
                    timeModifier={timeModifier}
                    useMinMax={useMinMax}
                    indent
                    autoFocus={selectedResource === resource.id}
                    disabled={disabled}
                    useTeamTogetherSwitch={!noTeamTogetherSwitch}
                    minStartMonth={minStartMonth}
                    {...rowProps}
                  />
                )
              )}
          </Fragment>
        })
      }
    </Grid>
  )
})

EditorContainer.propTypes = {
  fullSettings: bool,
  noTeamTogetherSwitch: bool,
  selectedWorkType: number,
  selectedResource: oneOfType([string, number]),
  section: object,
  filterWorkTypes: func
}