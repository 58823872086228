import { MAX_PAGE_WIDTH } from "@styles/themes";
import { CELL_WIDTHS } from "./settings";
import { Columns } from "project-structure";

export const calculateMaxBreakdownTitleCellWidth = (
  workTypes,
  usePrice,
  useVisibility,
  minMax,
  other=0
) => (
  MAX_PAGE_WIDTH
  - workTypes * ( minMax ? CELL_WIDTHS.VALUE.MEDIUM : CELL_WIDTHS.VALUE.SMALL )
  - (usePrice ? ( minMax ? CELL_WIDTHS.PRICE.MEDIUM : CELL_WIDTHS.PRICE.SMALL ) : 0)
  - (useVisibility ? CELL_WIDTHS.VISIBILITY : 0)
  - other
)

export const calculateMaxSummaryTitleCellWidth = (
  columns,
  visibility,
  minMax,
  usePrices,
  useVisibility,
  other=0,
) => {
  const width = columns.reduce((t, { name }) => {
    let w = 0;
    switch(name) {
      case Columns.COST:
        if(visibility.apply && visibility.hideSummaryCost)
          break;
        w = !usePrices ? 0 : ( minMax ? CELL_WIDTHS.PRICE.LARGE : CELL_WIDTHS.PRICE.SMALL );
        break;
      case Columns.C_UNIT_COST_INNER:
      case Columns.C_UNIT_COST:
      case Columns.HOURS:
        if(visibility.apply && visibility.hideSummaryHours)
          break;
      // eslint-disable-next-line no-fallthrough
      case Columns.TIME:
        if(visibility.apply && visibility.hideSummaryTime)
          break;
        w = CELL_WIDTHS.VALUE.LARGE;
        break;
      case Columns.RATE:
        if(visibility.apply && visibility.hideSummaryRate)
          break;
        w = !usePrices ? 0 : CELL_WIDTHS.VALUE.LARGE;
        break;
      case Columns.TEAM:
        if(visibility.apply && visibility.hideSummaryTeam)
          break;
        w = CELL_WIDTHS.VALUE.SMALL;
        break;
      case Columns.C_COST:
      case Columns.C_PROFIT:
        w = ( minMax ? CELL_WIDTHS.VALUE.LARGE : CELL_WIDTHS.VALUE.MEDIUM );
        break;
      case Columns.C_PROFITABILITY:
        w = CELL_WIDTHS.VALUE.MEDIUM
    }
    return t + w;
  }, 0)
  
  return MAX_PAGE_WIDTH
    - (useVisibility ? CELL_WIDTHS.VISIBILITY : 0)
    - width
    - other
}