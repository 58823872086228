import { useMemo } from "react";
import { arrayOf, bool, number, object, shape, string } from "prop-types";
import {
  useStructureStore,
  useFeatureGuardian,
  useTableExpandHandler,
  useProjectEditorStore,
} from "@hooks";
import { useTranslation } from "react-i18next";
import { observer } from "mobx-react";
import { Tables, Columns, hashCode } from "project-structure";
import { SummaryRowActions } from "./components/SummaryRowActions";
import { Collapse } from "@material-ui/core";
import { SummaryRowGroupList } from "../SummaryRowGroupList/SummaryRowGroupList";
import {
  AddButtonFull,
  WorkTypeSelect,
  PriceCell,
  TitleCell,
  ValueCell,
  Row,
  RowGroup,
  SummaryRow,
} from "@components";
import { ColumnFactory } from "./ColumnFactory";
import { ELEMENT_TYPES, LOCKED_FEATURES, CELL_WIDTHS } from "@utils";
import classnames from "classnames";

export const SummaryRowGroup = observer(({
  workType,
  useClientActions,
  isSellerOrClient,
  isEditorOrClient,
  allowEdition,
  isProposal,
  parentIds,
  blockExpansions,
  columns,
  maxTitleCellWidth,
  useExpandCell,
  useProfitability,
  noTurningOff,
}) => {
  const { t } = useTranslation();
  const { settings, workTypesHaveResources, usesTwoValues } = useStructureStore();
  const { visibilityMode } = useProjectEditorStore();

  const { checkAccess, FeatureAlert } = useFeatureGuardian(
    LOCKED_FEATURES.ALLOCATION
  );

  const {
    visibleSummaryColumns,
    hasTeamMembersCol,
    hasTimeCol,
  } = settings;

  const {
    id,
    name,
    resources,
    hasResources,
    isOpened,
    turnOff,
    hasOnlyCost,
    isHidden,
  } = workType;

    const expandable = useMemo(
      () =>
        hasTeamMembersCol &&
        ((allowEdition && !useProfitability) || hasResources),
      [hasTeamMembersCol, allowEdition, hasResources, useProfitability]
    );
    const opened = useMemo(
      () => hasTeamMembersCol && expandable && (isOpened || blockExpansions),
      [hasTeamMembersCol, expandable, isOpened, blockExpansions]
    );

    const hasHoursCell = useMemo(
      () =>
        Boolean(
          visibleSummaryColumns.find((col) => col.name === Columns.HOURS)
        ),
      [visibleSummaryColumns]
    );

    const hasRateCell = useMemo(
      () =>
        Boolean(visibleSummaryColumns.find((col) => col.name === Columns.RATE)),
      [visibleSummaryColumns]
    );

    const hasCostCell = useMemo(
      () =>
        Boolean(visibleSummaryColumns.find((col) => col.name === Columns.COST)),
      [visibleSummaryColumns]
    );

    const isActive = useMemo(
      () => opened && hasTeamMembersCol,
      [opened, hasTeamMembersCol]
    );

    const editorOrClient = useMemo(
      () => allowEdition || (isProposal && useClientActions),
      [allowEdition, isProposal, useClientActions]
    );

    const displayCellContent = useMemo(
      () => !(opened && hasResources),
      [opened, hasResources]
    );

    const displayClientCellContent = useMemo(
      () => !hasOnlyCost && isSellerOrClient && displayCellContent,
      [hasOnlyCost, isSellerOrClient, displayCellContent]
    );

    const { handleOpen, ...collapseProps } = useTableExpandHandler(
      expandable,
      workType.isOpened,
      workType.setOpenState
    );

    const handleNewResource = async () => {
      const allowed = await checkAccess();
      if (!allowed) return;
      workType.addResource(true, `r${new Date().getTime()}`, false);
    };

    const handleTurnOffState = () => {
      workType.setTurnOffState(!turnOff);
    };

    const handleResourceReorder = (list) => workType.reorderResources(list);

    const tableDepth = 1;

  return (
    <RowGroup
      originTableId={Tables.SUMMARY}
      tableDepth={tableDepth}
      isActive={isActive}
      presetClass="preset-accentSummary"
      isStatic={!allowEdition}
      elementId={id}
      elementType={ELEMENT_TYPES.WORK}
      className={classnames(visibilityMode && isHidden && "transparent-2")}
    >
      <Row
        presetClass={isActive ? "preset-activeSummary" : "preset-rowSummary"}
        useExpandCell={useExpandCell}
        expandable={expandable}
        expanded={isActive}
        onExpand={handleOpen}
        expandOnClick
        useVisibilityCell={useClientActions && !noTurningOff}
        visible={!turnOff}
        onVisibilityToggle={handleTurnOffState}
        isFirstGroupRow
        isLastGroupRow={!isActive}
        tableDepth={tableDepth}
        noArrow
      >
        <TitleCell
          minWidth={useProfitability ? CELL_WIDTHS.TITLE_SUMMARY_EMPTY : CELL_WIDTHS.TITLE_SUMMARY}
          maxWidth={useProfitability ? CELL_WIDTHS.TITLE_SUMMARY_EMPTY : maxTitleCellWidth}
          pinnedLeft={useExpandCell ? 1 : 0}
          allowOverflowDisplay
          actions={
            !useProfitability &&
            <SummaryRowActions
              allowEdition={allowEdition}
              workType={workType}
              showComments={isEditorOrClient && useClientActions}
              opened={isOpened}
            />
          }
        >
          {!allowEdition ? (
            <span
              className={classnames(
                "name",
                "preset-summaryTitleText",
                !name?.length && "semi-transparent",
                !useExpandCell && "ml-3"
              )}
            >
              {name || t("common.unnamed")}
            </span>
          ) : (
            <WorkTypeSelect
              workType={workType}
              useExpandCell={useExpandCell}
            />
          )}
        </TitleCell>
        {
          columns.map(({ name }) => (
            <ColumnFactory
              key={name}
              name={name}
              element={workType}
              allowEdition={allowEdition}
              isProposal={isProposal}
              useClientActions={useClientActions}
              isSellerOrClient={isSellerOrClient}
              editorOrClient={editorOrClient}
              displayCellContent={displayCellContent}
              displayClientCellContent={displayClientCellContent}
              profitabilityMode={useProfitability}
            />
          ))
        }
      </Row>
      {hasTeamMembersCol && (
        <Collapse
          in={opened}
          className="w-full"
          mountOnEnter
          unmountOnExit
          data-id={hashCode(`${id}_cont`)}
          data-p={[Tables.SUMMARY, id].map((id) => hashCode(id)).join("/")}
          data-t={ELEMENT_TYPES.RESOURCE}
          { ...collapseProps }
        >
          <SummaryRowGroupList
            listElements={resources}
            isProposal={isProposal}
            isSellerOrClient={isSellerOrClient}
            isEditorOrClient={isEditorOrClient}
            isLastGroupRow={!allowEdition || useProfitability}
            allowEdition={allowEdition}
            useClientActions={useClientActions}
            onReorder={handleResourceReorder}
            tableDepth={tableDepth}
            parentIds={[...parentIds, id]}
            columns={columns}
            maxTitleCellWidth={maxTitleCellWidth}
            useProfitability={useProfitability}
            turnOff={turnOff}
          />
          {allowEdition && !useProfitability && (
            <SummaryRow
              useExpandCell
              isLastGroupRow
              displayMinMax={usesTwoValues}
              useVisibilityCell={useClientActions && !noTurningOff}
              tableDepth={tableDepth}
            >
              <TitleCell
                allowOverflowDisplay
                minWidth={CELL_WIDTHS.TITLE_SUMMARY}
                pinnedLeft={!isProposal || workTypesHaveResources ? 1 : 0}
              >
                <AddButtonFull
                  id="newTeamMemberButton-summary"
                  onClick={handleNewResource}
                  name="Add new resource to work type"
                >
                  {t("views.editor.summary_table.work_resource_add")}
                </AddButtonFull>
              </TitleCell>
              {hasTeamMembersCol && <ValueCell wider />}
              {hasTimeCol && <ValueCell widest />}
              {hasRateCell && <ValueCell widest />}
              {isSellerOrClient && hasHoursCell && <ValueCell widest />}
              {isSellerOrClient && hasCostCell && <PriceCell widest={usesTwoValues} />}
            </SummaryRow>
          )}
        </Collapse>
      )}
      <FeatureAlert />
    </RowGroup>
  );
});

SummaryRowGroup.propTypes = {
  tableDepth: number,
  workType: object.isRequired,
  isSellerOrClient: bool,
  isEditorOrClient: bool,
  isProposal: bool,
  useClientActions: bool,
  allowEdition: bool,
  blockExpansions: bool,
  useExpandCell: bool,
  useProfitability: bool,
  columns: arrayOf(
    shape({
      name: string.isRequired,
    })
  ).isRequired,
  maxTitleCellWidth: number,
};
