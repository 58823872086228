import { bool, number, object } from "prop-types";
import { observer } from "mobx-react";
import { useTranslation } from "react-i18next";
import { TooltipIcon } from "@components";
import Grid from "@material-ui/core/Grid";
import { TimelinePresentationSelector } from "./TimelinePresentationSelector";
import { ModuleArrow, Percent } from "@assets";
import classnames from "classnames";

export const EditorRowTitle = observer(({
  element,
  sectionElement,
  gridSize,
  indent,
  transparent,
  fullSettings,
  hasSection,
}) => {
  const { t } = useTranslation();
  
  const { name, percentageWorkTypes, useTimelinePercent } = element;
  
  const hasPercent = sectionElement ? sectionElement.hasPercent : element.hasPercent;
  
  return (
    <Grid
      item
      container
      xs={gridSize}
      className={classnames(
        !indent && "font-bold",
        transparent && "semi-transparent"
      )}
      justifyContent="space-between"
      alignItems="center"
      wrap="nowrap"
    >
      <Grid item container alignItems="center">
        {indent && (
          <ModuleArrow className="mr-3" />
        )}
        <span className="ellipsis">
        {name || t("common.unnamed")}
        </span>
        {hasPercent && useTimelinePercent && (
          <TooltipIcon icon={<Percent />} color="primary" className="ml-3">
            {percentageWorkTypes?.map((wT) => wT.name).join(", ")}
          </TooltipIcon>
        )}
      </Grid>
      {
        !hasSection && hasPercent && fullSettings &&
        <TimelinePresentationSelector
          workType={element}
        />
      }
    </Grid>
  )
})

EditorRowTitle.propTypes = {
  element: object.isRequired,
  sectionElement: object,
  gridSize: number,
  indent: bool,
  transparent: bool,
  fullSettings: bool,
  hasSection: bool,
}