import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import ReactQuill from "react-quill";
import { ClickAwayListener, Grid } from "@material-ui/core";
import { PopMenu, SearchableListContent, SearchableListItem } from "@components";
import { QuillToolbar } from "@components/WysiwygEditor/components/QuillToolbar/QuillToolbar";
import { MAX_WYSIWYG_LENGTH, suggestMentionPeople, WYSIWYG_FORMATS, WYSIWYG_FORMATS_WITH_MENTIONS } from "@utils";
import { arrayOf, bool, func, node, number, object, oneOf, string } from "prop-types";
import useStyle from "@components/WysiwygEditor/WysiwygEditor.style";
import classnames from "classnames";

export const EditableContent = ({
  name,
  value,
  onBlur,
  onChange,
  popupAnchor,
  toolbarVariant,
  children,
  selection,
  useBorder,
  placeholder,
  editorMentionUsers,
  editorMentionSearchKeys,
}) => {
  
  const classes = useStyle();
  
  const [ loaded, setLoaded ] = useState(false);
  const [ userListVisible, showUsers ] = useState(false);
  const [ filteredUsers, setFilteredUsers ] = useState([]);
  
  const editorRef = useRef(null);
  const toolbarRef = useRef(null);
  
  useEffect(() => {
    editorCleanup();
    return () => {
      if(editorRef.current) {
        editorCleanup();
      }
    }
  }, []);
  
  const editorCleanup = () => {
    if(!editorRef.current) return;
    editorRef.current.unhookEditor(editorRef.current.getEditor());
    editorRef.current.destroyEditor();
  }
  
  const handleToolbarRef = useCallback((r) => {
    toolbarRef.current = r;
    setLoaded(Boolean(r));
  }, []);
  
  const handleEditorRef = useCallback((r) => {
    editorRef.current = r;
    r?.focus()
    // r?.history?.clear();
    if(r && selection)
      r.getEditor().setSelection(selection);
  }, [selection]);
  
  const handleChange = (val) => {
    if(!editorRef.current) return;
    
    const contentLength = editorRef.current.getEditor().getLength();
    if(contentLength > MAX_WYSIWYG_LENGTH) {
      editorRef.current?.getEditor()?.deleteText(MAX_WYSIWYG_LENGTH-1, contentLength);
      return;
    }
    
    const mentions = editorRef.current?.getEditor().getContents()?.ops
      ?.filter(ops => ops?.insert?.mention?.id)
      .map(ops => ({uuid: ops.insert.mention.id})) || [];
    
    onChange(val, mentions);
  }
  
  const handleBlur = () => {
    if(!editorRef.current)
      return;
    editorRef.current.blur();
    setLoaded(false);
    editorCleanup();
    onBlur && onBlur();
  }
  
  const filterMentionUsers = useCallback((searchTerm, renderList) => {
    if (searchTerm.length === 0) {
      const matchedPeople = suggestMentionPeople(editorMentionUsers);
      setFilteredUsers(matchedPeople);
      renderList(matchedPeople, searchTerm);
      return;
    }
    const matchedPeople = suggestMentionPeople(editorMentionUsers, editorMentionSearchKeys, searchTerm);
    setFilteredUsers(matchedPeople);
    renderList(matchedPeople, searchTerm);
  }, [])

  const addMention = (item) => async () => {
    editorRef.current?.getEditor()?.getModule('mention')?.insertItem({
      denotationChar: "@",
      ...item
    }, false)
  }
  
  const mentionModule = useMemo(() => ({
    source: filterMentionUsers,
    dataAttributes: ["value"],
    onOpen: () => showUsers(true),
    onClose: () => showUsers(false),
  }), []);
  
  return <ClickAwayListener mouseEvent="onMouseDown" onClickAway={handleBlur}>
    <Grid
      item container
      translate="no"
      className={classnames(
        useBorder && classes.bordered,
        useBorder && classes.borderedFocused,
        "ql-root w-full",
      )}
    >
      {
        loaded &&
        <ReactQuill
          ref={handleEditorRef}
          value={value}
          placeholder={placeholder}
          onChange={handleChange}
          className={classes.editor}
          theme={null}
          formats={editorMentionUsers?.length ? WYSIWYG_FORMATS_WITH_MENTIONS : WYSIWYG_FORMATS}
          modules={{
            magicUrl: true,
            mention: editorMentionUsers?.length ? mentionModule : undefined,
            clipboard: { matchVisual: false },
            history: {
              userOnly: true
            },
            toolbar: toolbarVariant === "none"
              ? false
              : toolbarRef.current
          }}
        />
      }
      {
        toolbarVariant === "popup" &&
        <PopMenu anchor={popupAnchor} show inline placement="top">
          <QuillToolbar
            ref={handleToolbarRef}
            id={name}
            float
            visible
          >
            {children}
          </QuillToolbar>
        </PopMenu>
      }
      {
        toolbarVariant === "inline" &&
        <QuillToolbar
          ref={handleToolbarRef}
          id={name}
          fullWidth
          visible
        >
          {children}
        </QuillToolbar>
      }
      {
        Boolean(editorMentionUsers) &&
        <SearchableListContent
          show={userListVisible}
          anchor={popupAnchor}
          placement="bottom"
          // offset={[0]}
          className={classes.mentionList}
          id="libraryElementSelector"
          onClickAway={() => showUsers(false)}
        >
          {
            filteredUsers.map(m => (
              <SearchableListItem
                key={m.id}
                onClick={addMention(m)}
              >
                {m.value}
              </SearchableListItem>
            ))
          }
        </SearchableListContent>
      }
    </Grid>
  </ClickAwayListener>
}

EditableContent.propTypes = {
  popupAnchor: object,
  name: string.isRequired,
  value: string,
  onBlur: func,
  onChange: func.isRequired,
  selection: number,
  toolbarVariant: oneOf(["none", "inline", "popup"]),
  children: node,
  useBorder: bool,
  placeholder: string,
  editorMentionUsers: arrayOf(object),
  editorMentionSearchKeys: arrayOf(string),
}