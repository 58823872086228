import { memo, useMemo } from "react";
import { bool, number, string, func, oneOfType, shape, node } from "prop-types";
import { ClearInput, ValueDivider } from "@components";
import classnames from "classnames";

export const ValueCellEditableContent = memo(({
  value,
  valueMax,
  onValueChange,
  displayMin=true,
  displayMax,
  symbolStart,
  symbolEnd,
  showDecimals,
  message,
  messageTitle,
  isSmall,
  min = 0,
  max = 9999,
  warning,
  className,
  displayMinMaxSeparator = true,
  fullWidth,
  allowNegative,
  decimals,
  inputClassName,
  inputMinTooltipMessage,
  inputMaxTooltipMessage,
  placeholderMin,
  placeholderMax,
}) => {
  const commonProps = useMemo(
    () => ({
      allowNegative,
      fixedDecimalScale: showDecimals,
      decimals,
      prefix: symbolStart,
      suffix: symbolEnd,
      color: warning ? "warning" : "primary",
      fullWidth: fullWidth || displayMax,
      size: isSmall ? "small" : "large",
    }),
    [warning, showDecimals, decimals, symbolStart, symbolEnd, max, min, isSmall]
  );

  const handleValueChange = (isMax) => (value) => (
    onValueChange && onValueChange(value, isMax)
  )

  return (
    <>
      {
        displayMin &&
        <ClearInput
          {...commonProps}
          value={value}
          onChange={handleValueChange(false)}
          clickDialogMessage={message}
          clickDialogMessageTitle={messageTitle}
          alignContent={displayMax ? "right" : "center"}
          min={typeof min === "number" ? min : min?.min}
          max={typeof max === "number" ? max : max?.min}
          tabIndex={message ? -1 : undefined}
          allowNegative={allowNegative}
          className={classnames(displayMin && displayMax ? "input-min" : "input-avg", className, inputClassName)}
          tooltipMessage={inputMinTooltipMessage}
          placeholder={placeholderMin}
        />
      }
      {
        displayMin && displayMax &&
        <ValueDivider display={displayMinMaxSeparator} />
      }
      {
        displayMax &&
        <ClearInput
          {...commonProps}
          value={valueMax}
          onChange={handleValueChange(true)}
          clickDialogMessage={message}
          clickDialogMessageTitle={messageTitle}
          alignContent={displayMin ? "left" : "center"}
          min={typeof min === "number" ? min : min?.max}
          max={typeof max === "number" ? max : max?.max}
          tabIndex={message ? -1 : undefined}
          className={classnames("input-max", className, inputClassName)}
          tooltipMessage={inputMaxTooltipMessage}
          placeholder={placeholderMax}
        />
      }
    </>
  );
});

ValueCellEditableContent.propTypes = {
  value: oneOfType([number, string]),
  valueMax: oneOfType([number, string]),
  onValueChange: func,
  displayMin: bool,
  displayMax: bool,
  displayMinMaxSeparator: bool,
  symbolStart: string,
  symbolEnd: string,
  placeholder: oneOfType([string, number]),
  warning: bool,
  isSmall: bool,
  fullWidth: bool,
  min: oneOfType([number, shape({ min: number, max: number })]),
  max: oneOfType([number, shape({ min: number, max: number })]),
  // common
  showDecimals: bool,
  allowNegative: bool,
  className: string,
  message: string,
  messageTitle: string,
  decimals: number,
  inputClassName: string,
  inputMinTooltipMessage: oneOfType([string, node]),
  inputMaxTooltipMessage: oneOfType([string, node]),
  placeholderMin: oneOfType([number, string]),
  placeholderMax: oneOfType([number, string]),
};
