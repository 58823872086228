import { useMemo, useState } from "react";
import { object } from "prop-types";
import { useTranslation } from "react-i18next";
import { observer } from "mobx-react";
import { useFeatureGuardian, useStructureStore } from "@hooks";
import { LOCKED_FEATURES } from "@utils";
import { AddButtonFull, Button, Dialog, Tag, TooltipIcon } from "@components";
import Grid from "@material-ui/core/Grid";
import { Badge, Divider } from "@material-ui/core";
import { OverheadEntry } from "./components/OverheadEntry/OverheadEntry";
import { NewOverhead } from "./components/OverheadEntry/NewOverhead";
import { Stacks } from "@assets";
import classnames from "classnames";

export const Overheads = observer(({ section }) => {
  const structure = useStructureStore();
  const { t } = useTranslation();
  
  const [visible, show] = useState(false);
  const [tmpOverhead, setTmpOverhead] = useState(null);
  
  const { checkAccess, FeatureAlert } = useFeatureGuardian(
    LOCKED_FEATURES.OVERHEADS
  );
  const { checkAccess: checkCustomAccess, FeatureAlert: FeatureCustomAlert } = useFeatureGuardian(
    LOCKED_FEATURES.CUSTOM_OVERHEADS
  );
  const globalOverheads = section ? structure.settings.overheads || [] : [];
  const displayOverheads = (section ? section.overheads  : structure.settings.overheads) || [];
  
  const usedOverheads = (
    globalOverheads.filter(ov => ov.used)?.length
    + displayOverheads.filter(ov => ov.used)?.length
  );
  
  const dialogTitle = useMemo(() => (
    t(`views.editor.dialogs.overheads.title_${section ? "local" : "global" }`)
  ), []);
  
  const availableWorkTypes = (section ? section.workTypes : structure.workTypes)?.map(wT => wT.id);
  
  const handleOpen = async () => {
    const allowed = await checkAccess();
    if (!allowed) return;
    show(true);
  }
  
  const handleClose = () => {
    show(false);
    setTmpOverhead(null);
  }
  
  const addNewOverhead = () => {
    structure.historyManager.startGroup();
    (section || structure.settings).addOverhead(tmpOverhead);
    if(section)
      section.resetOverheadPercentValues(tmpOverhead.workTypes);
    else {
      structure.sections.forEach(s => s.resetOverheadPercentValues(tmpOverhead.workTypes));
      tmpOverhead.workTypes.forEach(id => structure.generateTimeline(id))
    }
    
    setTmpOverhead(null);
    structure.historyManager.stopGroup();
  }
  
  const addTmpOverhead = async () => {
    const allowed = await checkCustomAccess();
    if (!allowed) return;
    
    const name = t("views.editor.dialogs.overheads.name_new");
    const oldNameUses = displayOverheads.filter(ov => (
      ov.name.includes(name)
    )).length;
    
    setTmpOverhead({
      id: `ov${new Date().getTime()}`,
      name: oldNameUses > 0 ? `${name} (${oldNameUses+1})` : name,
      percent: 0,
      workTypes: [],
      used: true,
    });
  }
  
  const handleTmpOverheadChange = (updatedData) => {
    setTmpOverhead({
      ...tmpOverhead,
      ...updatedData
    })
  }
  
  return (
    <>
      <Badge
        badgeContent={usedOverheads}
        overlap="circular"
        color="secondary"
        aria-label="number of used overheads"
        max={9}
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        classes={{
          badge: classnames(!section && "r-1")
        }}
      >
        {
          section
            ? <TooltipIcon
              id="summarySettings"
              icon={<Stacks />}
              onClick={handleOpen}
              className={classnames("button-overheads noDrag color-primary-lighter", !usedOverheads && "hoverIcon opaque")}
              color="primary"
            >
              {dialogTitle}
            </TooltipIcon>
            : <Button
              id="summarySettings"
              onClick={handleOpen}
              aria-label={dialogTitle}
              color="secondary"
              endIcon={<Stacks color="primary" />}
              className="font-normal"
            >
              {dialogTitle}
            </Button>
        }
      </Badge>
      <Dialog
        open={visible}
        onClose={handleClose}
        width={600}
        title={<Grid container alignItems="center">
          <h2>{dialogTitle}</h2>
          {
            Boolean(section) &&
            <Tag text={section.name} className="ml-2" />
          }
        </Grid>}
        actions={
          <Button
            variant="contained"
            color="secondary"
            onClick={handleClose}
          >
            {t("common.close")}
          </Button>
      }
      >
        <Grid item container direction="column">
          {
            globalOverheads?.length > 0 &&
            <>
              {
                globalOverheads.map((overhead) => (
                  <OverheadEntry
                    isGlobal
                    overhead={overhead}
                    key={overhead.id}
                    availableWorkTypes={overhead.workTypes}
                  />
                ))
              }
              <Divider className="my-3" />
            </>
          }
          {
            displayOverheads.map((overhead) => (
              <OverheadEntry
                overhead={overhead}
                key={overhead.id}
                availableWorkTypes={availableWorkTypes}
              />
            ))
          }
          {
            Boolean(displayOverheads.length) &&
            <Divider className="my-3" />
          }
          {
            tmpOverhead
              ? <NewOverhead
                onChange={handleTmpOverheadChange}
                onAccept={addNewOverhead}
                onCancel={() => setTmpOverhead(null)}
                availableWorkTypes={availableWorkTypes}
                {...tmpOverhead}
              />
              : <AddButtonFull
                id="createOverhead"
                onClick={addTmpOverhead}
                name={t("views.editor.dialogs.overheads.create")}
              >
                {t("views.editor.dialogs.overheads.create")}
              </AddButtonFull>
          }
        </Grid>
      </Dialog>
      <FeatureAlert />
      <FeatureCustomAlert />
    </>
  );
});

Overheads.propTypes = {
  section: object,
}