import { createContext } from "react";
import { arrayOf, bool, node, object, oneOf, string } from "prop-types";
import { action, computed, makeObservable, observable } from "mobx";
import {
  universalDateParser,
  parseUsedPages,
  WT_STATUS,
  VERTICAL_PADDING,
  HORIZONTAL_PADDING,
  SCALE_PRESETS,
  getPdfPage,
  PROPOSAL_THUMB_ID,
  PAGE_VISIBLE_BOTTOM_OFFSET,
  PROJECT_TYPE,
  TABLE_CONTAINER_ID, ShareLinkModifiers,
} from "@utils";
import {
  StructureModel,
  Tables,
  unzipStructure,
} from "project-structure";

export const ProjectEditorStoreContext = createContext(null);
export const ProjectEditorStoreProvider = ({ children, ...storeData }) => (
  <ProjectEditorStoreContext.Provider value={new ProjectEditorStore(storeData)}>
    {children}
  </ProjectEditorStoreContext.Provider>
);

export const ProjectEditorStoreProviderV2 = ({ children, value }) => (
  <ProjectEditorStoreContext.Provider value={value}>
    {children}
  </ProjectEditorStoreContext.Provider>
);

ProjectEditorStoreProvider.propTypes = {
  children: node.isRequired,
  projectType: oneOf([
    PROJECT_TYPE.STANDARD,
    PROJECT_TYPE.LIBRARY,
    PROJECT_TYPE.MINI,
  ]).isRequired,
  projectData: object,
  workTypes: arrayOf(object),
  teamMembers: arrayOf(object),
  logo: string,
  blockedAverageAlert: bool,
  blockedRiskAlert: bool,
  blockedValueAlert: bool,
  hadDifferentValues: bool,
  useHistoryManager: bool,
  unzipped: bool,
  isProposal: bool,
  btIssues: object,
};

export class ProjectEditorStore {
  constructor({
    projectType,
    projectData,
    workTypes,
    logo,
    blockedAverageAlert,
    blockedRiskAlert,
    blockedValueAlert,
    hadDifferentValues,
    unzipped,
    isProposal,
    teamMembers,
    useHistoryManager,
    btIssues,
  }) {
    makeObservable(this);

    this.isStandardProject = projectType === PROJECT_TYPE.STANDARD;

    if (logo) this.setCompanyLogo(logo);
    if (blockedAverageAlert) this.blockedAverageAlert = blockedAverageAlert;
    if (blockedRiskAlert) this.blockedRiskAlert = blockedRiskAlert;
    if (blockedValueAlert) this.blockedValueAlert = blockedValueAlert;
    if (hadDifferentValues) this.hadDifferentValues = hadDifferentValues;
    if (useHistoryManager) this.useHistoryManager = useHistoryManager;
    if (workTypes) this.setWorkTypes(workTypes);
    if (projectData)
      this.setProjectData(projectData, isProposal, unzipped, btIssues);
    if (teamMembers) this.setTeamMembers(teamMembers);
  }

  // =============== PROPERTIES ===============
  blockedAverageAlert;
  blockedRiskAlert;
  blockedValueAlert;
  hadDifferentValues;
  useHistoryManager;

  @observable teamMembers = [];

  @observable projectLoaded = false;
  @observable projectName;
  @observable projectDesc;
  @observable projectDue;
  @observable projectCategory;
  isStandardProject;

  @observable proposalStep = 0;

  @observable projectStatus;
  @observable projectUsers;
  @observable projectId;
  @observable projectUserWorkTypeVisibility = {};
  @observable companyLogo = null;
  @observable shareLinkSettings = null;

  @observable workTypes = [];
  @observable workTypesWithStatus = [];

  @observable shareModalForced = false;
  @observable previewMode = false;

  @observable projectVersions = [];
  @observable currentVersion;
  @observable newVersionLoading;

  defaultCurrency;
  defaultRates;
  timeEquivalents;
  
  @observable visibilityMode = false;
  @observable allowVisibility = false;
  
  @observable profitabilityMode = false;
  @observable allowProfitability = false;
  
  @observable currencyExchangeRate = 1;

  // ----------- PDF -----------

  @observable pdfDocument;
  @observable newPdfFile = false;
  @observable currentPage = 0;
  @observable totalPages = 0;
  @observable allPdfPages = [];
  @observable usedPdfPages = [];
  @observable proposalTablePosition;
  basePagesUrl;
  documentChanged;

  containerRef = null;
  pageViewRef = null;
  thumbViewRef = null;

  tmpScale = 1;
  @observable scale = 1;
  @observable scaleValue = "page-fit";

  // =============== COMPUTED-S ===============

  @computed get isArchived() {
    return this.projectStatus === "archive";
  }

  @computed get currentProjectStructure() {
    return this.projectVersions?.find((v) => v.key === this.currentVersion)
      ?.structure;
  }
  @computed get currentVersionKey() {
    return this.projectVersions?.find((v) => v.key === this.currentVersion)
      ?.key;
  }
  @computed get sortedVersions() {
    return this.projectVersions
      .slice()
      .sort((a, b) => (a.order > b.order ? 1 : -1));
  }
  @computed get visibleProjectVersions() {
    return this.projectVersions.filter(
      ({ structure }) => structure?.visibility?.version
    ).length;
  }

  @computed get pdfPages() {
    return this.usedPdfPages
      .slice()
      .sort((a, b) => (a[1] > b[1] ? 1 : -1))
      .map(([p]) => p);
  }

  pageMapFunc = (p) => this.allPdfPages[p - 1] || { id: p };
  @computed get pdfPageThumbnails() {
    return this.isStandardProject
      ? [
          ...this.headerPages.map(this.pageMapFunc),
          { id: PROPOSAL_THUMB_ID },
          ...this.footerPages.map(this.pageMapFunc),
        ]
      : this.pdfPages.map(this.pageMapFunc);
  }

  @computed get numPages() {
    return this.pdfPageThumbnails.length;
  }

  @computed get headerPages() {
    if (this.isStandardProject)
      return this.pdfPages.slice(0, this.proposalTablePosition);

    return this.pdfPages;
  }

  @computed get footerPages() {
    if (this.isStandardProject)
      return this.pdfPages.slice(this.proposalTablePosition);

    return [];
  }

  @computed get pdfPagesLoaded() {
    return (
      !this.pdfDocument ||
      (this.totalPages > 0 &&
        this.allPdfPages.filter((p) => !!p).length === this.totalPages)
    );
  }

  @computed get widestPageSize() {
    let width = 0,
      height = 0;

    this.allPdfPages.forEach((page) => {
      if (!page) return;
      if (page.originalWidth > width) {
        width = page.originalWidth;
        height = page.originalHeight;
      }
    });

    return { width, height };
  }
  
  @computed get nonOwnerTeamMembers() {
    return this.teamMembers.filter(user => !(
      (this.projectUsers?.author && user.uuid === this.projectUsers?.author?.uuid) ||
      (this.projectUsers?.owner && user.uuid === this.projectUsers?.owner?.uuid)
    ))
  }
  
  @computed get noTurningOff() {
    return this.shareLinkSettings?.[ShareLinkModifiers.NO_TURN_OFF] || false;
  }
  
  // =============== GETTERS ===============
  
  hasAssignedWorkTypes(userUuid) {
    return Boolean(this.workTypesWithStatus.find(w => w.estimatorUuid === userUuid));
  }

  getWorkTypeDefaultRate(workTypeId) {
    return (this.defaultRates?.[workTypeId] || 0) / 60;
  }
  
  getWorkTypeName(workTypeId) {
    return this.workTypes?.find(wT => wT.id === workTypeId)?.name;
  }

  getPageImageUrl(pageNum) {
    return `${this.basePagesUrl}_${pageNum}.png`;
    // const s = decodeURIComponent(this.basePagesUrl).split("/");
    // s[s.length-1] = encodeURIComponent(s[s.length-1]);
    // return `${s.join("/")}_${pageNum}.png`
  }
  
  getProjectUser(userUuid) {
    return this.projectUsers.observer.find(u => u.uuid === userUuid);
  }
  
  isProjectUser(userUuid) {
    return Boolean(this.projectUsers.observer.find(u => u.uuid === userUuid))
  }

  getProjectId() {
    return this.projectData;
  }

  // =============== ACTIONS ===============
  @action setTeamMembers = (teamMembers) => {
    this.teamMembers = teamMembers;
  };

  @action setProjectName = (name) => (this.projectName = name);

  @action setProjectStatus(status) {
    return (this.projectStatus = status);
  }

  @action setWorkTypes = (workTypes) => {
    this.workTypes = workTypes;
  };

  @action setProjectUsers = (users) => {
    this.projectUsers = users;
  };
  @action setProjectUserWorkTypeVisibility = (userId, status) => {
    this.projectUserWorkTypeVisibility = {
      ...this.projectUserWorkTypeVisibility,
      [userId]: status ? 1 : 0
    };
  };

  @action setCompanyLogo = (companyLogo) => {
    this.companyLogo = companyLogo;
  };

  @action switchProfitabilityMode = () => {
    this.profitabilityMode = !this.profitabilityMode;
    if (this.visibilityMode) this.visibilityMode = false;
  };
  
  @action setAllowProfitability(allowProfitability) {
    this.allowProfitability = allowProfitability
  }
  
  @action setAllowVisibility(allowVisibility) {
    this.allowVisibility = allowVisibility
  }

  @action setShareLinkSettings(key, value) {
    if(!this.shareLinkSettings)
      this.shareLinkSettings = {};
    
    this.shareLinkSettings[key] = value;
  }
  
  @action setCurrencyExchangeRate = (
    companyCurrencyRate,
    projectCurrencyRate
  ) => {
    this.currencyExchangeRate = companyCurrencyRate * (1 / projectCurrencyRate);
  };

  @action cleanup = () => {
    this.setPreviewMode(0);
    this.setProposalStep(0);
    this.profitabilityMode = false;
    this.visibilityMode = false;

    this.projectVersions = [];
    this.currentVersion = undefined;

    this.projectDesc = undefined;
    this.projectDue = undefined;
    this.projectStatus = undefined;
    this.projectCategory = undefined;
  };

  @action forceShareModal = (state) => {
    this.shareModalForced = state;
  };

  @action setPreviewMode = () => (this.previewMode = !this.previewMode);

  @action setProposalStep = (v, m) => {
    this.proposalStep = v !== null ? v : this.proposalStep + m;
    this.previewMode = false;
    this.visibilityMode = false;
    this.profitabilityMode = false;

    this.projectVersions.forEach((ver) => {
      ver.structure.visibility?.applyVisibility(this.proposalStep > 0);
    });
    // @todo: check
    // if(this.proposalStep === 0)
    //   this.rootStore.presetStore.presetChanged = false;
  };

  @action useVisibilityMode = (use) => {
    this.visibilityMode = use;
    if (this.profitabilityMode) this.profitabilityMode = false;
    this.projectVersions.forEach((ver) => {
      ver.structure.visibility?.applyVisibility(use);
    });
  };

  @action
  setProjectData(
    projectData,
    isProposal = false,
    unzipped = false,
    btIssues = null
  ) {
    this.projectLoaded = false;
    const {
      // uuid,
      name,
      content,
      tags,
      versions,
      category,
      logo,
      dueOn,
      status,
      users,
      visible,
      covers,
      defaultCurrency,
      defaultRates,
      timeEquivalents,
      timeline,
      shareLinkSettings,
    } = projectData;
    this.setWorkTypesWithStatus(tags);

    // if (uuid) this.projectUuid = uuid;
    if (name) this.projectName = name;
    if (logo) this.companyLogo = logo;
    if (shareLinkSettings && typeof shareLinkSettings === "string")
      this.shareLinkSettings = JSON.parse(shareLinkSettings);

    if (versions?.length) {
      const parsedVer = versions.map(( v ) => this.unzipVersion(v, isProposal, isProposal && !timeline, unzipped))
      const hasVisibleVersion = parsedVer.find(v => v.structure.visibility?.version);
      const smallestOrder = parsedVer.reduce((min, v) => Math.min(v.order, min), Infinity);
      
      this.projectVersions = parsedVer.filter(v => (!hasVisibleVersion && v.order === smallestOrder) || !isProposal || v.structure.visibility?.version);
      
      if ( this.currentVersion === undefined )
        this.currentVersion = this.sortedVersions[0].key;

      if (btIssues && typeof btIssues === "object") {
        this.currentVersion = btIssues.version;
        const versionIndex = versions.findIndex(
          (item) => item.key === this.currentVersion
        );
        const arrayIdWT = Object.values(btIssues.workTypes);

        let path;

        if (btIssues.issues[0].workTypes["-200"] >= 0) {
          const usedWorkTypes = this.projectVersions[
            versionIndex
          ].structure.workTypes.filter((workType) =>
            arrayIdWT.includes(workType.id)
          );

          const sumWTRate = usedWorkTypes.reduce((totalRate, workType) => {
            return (
              totalRate +
              workType.rate *
                this.projectVersions[versionIndex].structure.settings.modifier
            );
          }, 0);

          const averageWT =
            sumWTRate /
            usedWorkTypes.length /
            this.projectVersions[versionIndex].structure.settings.modifier;

          const nameWorkTypes = usedWorkTypes
            .map((workType) => {
              return workType.name;
            })
            .join(", ");
          this.projectVersions[versionIndex].structure.addWorkType(
            false,
            {
              id: -200,
              name: nameWorkTypes,
            },
            averageWT
          );
          this.projectVersions[versionIndex].structure.settings.setBtWorkTypes(
            arrayIdWT
          );
          this.projectVersions[versionIndex].structure.sections.forEach(
            (section) => {
              section.addWorkType(-200);
            }
          );
        } else {
          this.projectVersions[versionIndex].structure.workTypes.forEach(
            (wT) => {
              if (!arrayIdWT.includes(wT.id)) wT.setTurnOffState(true);
            }
          );
        }

        this.projectVersions[versionIndex].structure.sections.forEach((sec) => {
          if (!btIssues.sections.includes(sec.id)) sec.setTurnOffState(true);
        });
        btIssues.issues.forEach((item) => {
          path = `/sections/` + item.index.replace(/\./g, `/children/`);
          const el = path
            .split("/")
            .splice(1)
            .reduce(
              (node, key) => node[key],
              this.projectVersions[versionIndex].structure
            );
          el.setBTValues(item.workTypes);
        });

        btIssues.newIssues.forEach((item) => {
          this.projectVersions[versionIndex].structure.attachSection(item);
        });

        if (typeof btIssues.depth === "number")
          this.projectVersions[versionIndex].structure.settings.setValueLevel(
            btIssues.depth
          );
      }
    }

    if (isProposal && this.projectVersions[0].structure.visibility) {
      this.projectVersions.forEach((ver) => {
        ver.structure.visibility?.applyVisibility(true);
      });
    }

    if (covers?.[0]) {
      this.setPdfDocument(covers[0]);
    }

    this.projectDue = dueOn && universalDateParser(dueOn);
    this.projectStatus = status;
    this.projectDesc = content;
    this.projectCategory = category;
    this.projectUsers = users;
    this.projectId = projectData.id;
    this.projectUserWorkTypeVisibility = visible || {};

    this.defaultCurrency = defaultCurrency;
    this.defaultRates = defaultRates;
    this.timeEquivalents = timeEquivalents;

    this.projectLoaded = true;
  }

  @action unzipVersion(
    { structure, ...versionData },
    isProposal = false,
    noTimeline = false,
    unzipped = false
    // ignoreVisibility = false
  ) {
    const p = unzipped ? structure : JSON.parse(unzipStructure(structure));
    // if(isProposal && !p?.visibility?.version && !ignoreVisibility)
    //   return;
    
    const data = {
      ...versionData,
      structure: StructureModel.create(p),
    };

    if (noTimeline)
      data.structure.settings?.usedTables
        ?.find((t) => t.name === Tables.TIMELINE)
        ?.setVisibility(false);

    if (this.useHistoryManager) data.structure.initHistoryManager();

    return data;
  }

  @action addCustomTag(id, name, backgroundColor) {
    this.workTypes.push({
      id,
      name,
      backgroundColor,
      estimator: null,
      estimatorUuid: null,
      status: WT_STATUS.PENDING,
    });
    // this.addWorkTypeWithStatus({
    //   id,
    //   name,
    //   backgroundColor,
    // });
    this.addWorkTypeWithStatus(id);
  }

  // ----------- VERSIONS -----------

  @action setNewVersionLoading() {
    this.newVersionLoading = true;
  }

  @action addNewVersion(versionData, noFocus = false) {
    const version = this.unzipVersion(versionData);
    // console.log("VERSION DATA:", versionData);
    this.projectVersions.push(version);
    this.newVersionLoading = false;
    if (!noFocus) this.setCurrentVersion(version.key);
  }

  @action setCurrentVersion(key) {
    this.currentVersion = key;
  }

  @action editVersionName(versionKey, name) {
    const version = this.projectVersions.find((v) => v.key === versionKey);
    version.name = name;
    version.structure.setVersionName(name);
  }

  @action setVersionHidden(versionKey, visible) {
    const version = this.projectVersions.find((v) => v.key === versionKey);
    version?.structure?.visibility?.setVersionVisibility(visible);
  }
  
  @action setEditionLock(versionKey, locked) {
    const version = this.projectVersions.find((v) => v.key === versionKey);
    version?.structure?.setEditionLock(locked);
  }

  @action removeSelectedVersion(versionKey) {
    const index = this.projectVersions.findIndex((v) => v.key === versionKey);

    this.projectVersions.splice(index, 1);
    if (this.currentVersion === versionKey) {
      this.setCurrentVersion(
        this.projectVersions[index === 0 ? index : index - 1].key
      );
    }
  }

  @action updateVersionOrder(versionOrder) {
    this.projectVersions.forEach((v) => {
      const o = versionOrder.find((vO) => vO[0] === v.key);
      if (o) v.order = o[1];
    });
  }

  @action reorderVersions(list) {
    this.projectVersions.forEach((v) => {
      v.order = list.indexOf(v.key);
    });
  }

  // ----------- ASSIGNMENTS -----------

  @action setWorkTypesWithStatus = (workTypes) => {
    this.workTypesWithStatus = workTypes;
  };
  @action addWorkTypeWithStatus = (workTypeId) => {
    const wT = this.workTypes.find((t) => t.id === workTypeId);
    if (!wT) return;

    this.workTypesWithStatus.push({
      ...wT,
      estimator: null,
      estimatorUuid: null,
      status: WT_STATUS.PENDING,
    });
  };

  @action addLocalPermit(workTypeId, userUuid) {
    const workTypes = this.workTypesWithStatus.slice(0);
    const wT = workTypes.find((wT) => wT.id === workTypeId);
    wT.estimatorUuid = userUuid;
    this.workTypesWithStatus = workTypes;
  }
  @action removeLocalPermit(workTypeId) {
    const workTypes = this.workTypesWithStatus.slice(0);
    const wT = workTypes.find((wT) => wT.id === workTypeId);
    wT.estimatorUuid = undefined;
    this.workTypesWithStatus = workTypes;
  }

  @action updateLocalPermit(workTypeId, permit) {
    const workTypes = this.workTypesWithStatus.slice(0);
    const wT = workTypes.find((wT) => wT.id === workTypeId);
    wT.status =
      permit ||
      (wT.status === WT_STATUS.PENDING
        ? WT_STATUS.ESTIMATED
        : WT_STATUS.PENDING);
    this.workTypesWithStatus = workTypes;
    return wT.status;
  }

  // ----------- PDF -----------

  setContainerRef = (r) => (this.containerRef = r);
  setPageViewRef = (r) => (this.pageViewRef = r);
  setThumbViewRef = (r) => (this.thumbViewRef = r);

  @action setPdfDocument = (document, newPdfFile = false) => {
    this.removeDocument();
    this.newPdfFile = newPdfFile;
    this.pdfDocument = document;
    this.usedPdfPages = parseUsedPages(document.usedPages);
    this.proposalTablePosition = Number(document.tablePos);
    this.scale = Number(document.scale || 1);
    if (document.scale) this.scaleValue = SCALE_PRESETS.CUSTOM;

    this.basePagesUrl = decodeURIComponent(document.file.path).match(
      /(https?:\/\/.*.s3.*\.com\/.*\/.*)\.pdf/
    )[1];
  };

  @action async setupDocument(data) {
    this.pdfDocument.fileData = await data.getData();
    this.totalPages = data.numPages;
    if (this.newPdfFile || !this.usedPdfPages.length) {
      const usedPdfPages = parseUsedPages("[]", data.numPages);
      this.usedPdfPages = usedPdfPages;
      this.newPdfFile = false;
      this.scaleValue = SCALE_PRESETS.FIT;
      this.setScale(undefined, SCALE_PRESETS.FIT);
      return { usedPdfPages, scale: this.scale };
    }
  }

  @action handlePageLoaded(pageNum, originalWidth, originalHeight) {
    this.allPdfPages[pageNum - 1] = {
      id: pageNum,
      originalWidth,
      originalHeight,
      el: getPdfPage(pageNum),
    };
  }

  @action setScale(value, scaleValue = undefined, noPageUpdate = false) {
    if (!this.pageViewRef || !this.pdfDocument) return;
    if (!value && this.scaleValue === SCALE_PRESETS.CUSTOM) return;
    if (value) this.documentChanged = true;

    if (!value) value = this.scaleValue;

    let scale = parseFloat(value);

    if (scale > 0) {
      if (scale < 0.1) scale = 0.1;
      if (scale > 10) scale = 10;
    } else {
      const { width, height } = this.widestPageSize;

      if (width === 0) return;

      const containerWidth =
        this.pageViewRef.clientWidth - 2 * HORIZONTAL_PADDING;
      const pageWidthScale = containerWidth / width;

      const pageHeightScale =
        (this.pageViewRef.clientHeight - 2 * VERTICAL_PADDING) / height;

      switch (value) {
        case SCALE_PRESETS.WIDTH:
          scale = pageWidthScale;
          break;
        case SCALE_PRESETS.FIT:
          scale = Math.min(pageWidthScale, pageHeightScale);
          break;
        case SCALE_PRESETS.AUTO:
          scale = Math.min(1.25, Math.min(pageHeightScale, pageWidthScale));
          break;
        default:
          console.error(`_setScale: "${value}" is an unknown zoom value.`);
          return this.pdfDocument;
      }
    }

    if (!noPageUpdate) this.applyScale(scale, scaleValue || value);

    this.pdfDocument.scale = parseFloat(value);
    this.findCurrentPage();
    return {
      scale,
      scaleValue: scaleValue || value,
    };
  }

  @action applyScale(scale, scaleValue) {
    if (scale) this.scale = scale;
    if (scaleValue) this.scaleValue = scaleValue;
  }

  @action removeDocument() {
    this.pdfDocument = undefined;
    this.allPdfPages = [];
    this.usedPdfPages = [];
    this.currentPage = 0;
    this.totalPages = 0;
    this.scale = 1;
    this.scaleValue = 1;
    this.proposalTablePosition = -1;
  }

  @action removePage(pageId) {
    const index = this.usedPdfPages.find(([p]) => p === pageId)?.[1];
    this.usedPdfPages = this.usedPdfPages.filter((p) => {
      if (p[1] > index) p[1] = p[1] - 1;
      return p[0] !== pageId;
    });

    if (index < this.proposalTablePosition) {
      this.proposalTablePosition = this.proposalTablePosition - 1;
    }

    return {
      usedPages: this.usedPdfPages,
      tablePos: this.proposalTablePosition,
    };
  }

  @action jumpToPage(pageIndex) {
    const element =
      pageIndex === this.proposalTablePosition
        ? document.getElementById(TABLE_CONTAINER_ID)
        : this.pdfPageThumbnails[pageIndex]?.el;
    if (!element) return;
    this.pageViewRef.scrollTop = element.offsetTop - VERTICAL_PADDING;
  }

  @action reorderPages(list) {
    const proposalIndex = list.findIndex((p) => p.id === PROPOSAL_THUMB_ID);

    if (proposalIndex >= 0) {
      this.proposalTablePosition = proposalIndex;
      list.splice(proposalIndex, 1);
    }

    this.usedPdfPages = list.map(({ id }, i) => [id, i]);

    return {
      usedPages: this.usedPdfPages,
      tablePos: this.proposalTablePosition,
    };
  }

  @action findCurrentPage() {
    if (!this.pdfPagesLoaded) return;

    const tableContainer = document.getElementById(TABLE_CONTAINER_ID);
    const containerRect = this.pageViewRef.getBoundingClientRect();
    const pageIndex = this.pdfPageThumbnails.findIndex((p) => {
      const el = p.el || tableContainer;
      if (!el) return;
      const elementRect = el.getBoundingClientRect();
      return elementRect.y + PAGE_VISIBLE_BOTTOM_OFFSET > containerRect.y;
    });

    this.currentPage = pageIndex >= 0 ? pageIndex : this.totalPages;

    if (this.thumbViewRef) {
      const thumbContainerRect = this.thumbViewRef.getBoundingClientRect();
      const thumbEl =
        this.currentPage === this.proposalTablePosition
          ? tableContainer
          : document.getElementById(`page${this.currentPage}`);

      if (!thumbEl) return;
      if (
        thumbEl.getBoundingClientRect()?.y >
          thumbContainerRect.y + thumbContainerRect.bottom ||
        thumbEl.getBoundingClientRect()?.y < thumbContainerRect.y
      )
        this.thumbViewRef.scrollTop = thumbEl.offsetTop - VERTICAL_PADDING;
    }
  }

  @action setUsedPages(usedPdfPages) {
    this.usedPdfPages = usedPdfPages;
  }

  @action setTablePos(tablePos) {
    this.proposalTablePosition = tablePos;
  }
}
