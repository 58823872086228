import { useMemo } from "react";
import { bool, string, object, number } from "prop-types";
import { observer } from "mobx-react";
import { useTranslation } from "react-i18next";
import { useStructureStore, usePresetFieldNames } from "@hooks";
import { TABLE_DRAGGABLE_CONTAINER, CELL_WIDTHS } from "@utils";
import {
  TooltipIcon,
  Link,
  HeaderRow,
  PriceCell,
  TitleCell,
  SortableList,
  DescriptionCell,
} from "@components";
import Grid from "@material-ui/core/Grid";
import { HeaderWorkType } from "./components/HeaderWorkType";
import { HeaderWorkTypeEditor } from "./components/HeaderWorkTypeEditor";
import { Warning } from "@assets";
import classnames from "classnames";

export const TableHeader = observer(({
  section,
  tableIdentifier,
  allowEdition,
  blockExpansions,
  isSellerOrClient,
  useClientActions,
  isProposal,
  isLibrary,
  useExpandCell,
  useDescriptionCell,
  maxTitleCellWidth,
  maxDescriptionCellWidth,
  pricesVisible,
  visibleWorkTypes,
  noTurningOff,
}) => {
  const structure = useStructureStore();
  const { estimateViewLevel, usesTwoValues } = structure;

  const {
    currencyObj,
    showBreakdownColumns,
    sectionFormat,
    descriptionMode,
    descriptionCellWidth,
    titleCellWidth,
  } = structure.settings;
  
  const {
    allChildrenExpanded,
    startingLevel,
    sectionModulesHaveChildren,
    isOpened,
    hasChildrenHours,
    taskWorkTypes,
  } = section;

  const { t } = useTranslation();
  const { getFieldName } = usePresetFieldNames(isProposal);

  const expanded = useMemo(
    () => (sectionFormat !== 1 || isOpened) && allChildrenExpanded,
    [allChildrenExpanded, isOpened, sectionFormat]
  );
  
  const showDescriptionCell = useMemo(() => (
    descriptionMode === 3 && useDescriptionCell
  ), [descriptionMode, useDescriptionCell]);

  const expandAllChildren = () => section.expandAllChildren(!expanded);

  const handleWorkTypeReorder = (list) => section.reorderWorkTypes(list);
  
  const handleTitleCellRescale = (w) => {
    structure.settings.setTitleCellWidth(w);
  }
  const handleDescriptionCellRescale = (w) => {
    structure.settings.setDescriptionCellWidth(w);
  }
  
  return (
    <>
      <HeaderRow
        id={ tableIdentifier }
        useExpandCell={ useExpandCell }
        hideVisibilitySwitch
        useVisibilityCell={ useClientActions && !noTurningOff }
        expandable={ !blockExpansions && estimateViewLevel > 1 }
        expanded={ expanded }
        onExpand={ expandAllChildren }
        expandCellPresetClass="preset-tableBreakdownHeader"
      >
        <TitleCell
          allowOverflowDisplay={ descriptionMode < 3 }
          minWidth={ showDescriptionCell ? titleCellWidth : CELL_WIDTHS.TITLE }
          maxWidth={ maxTitleCellWidth }
          pinnedLeft={ !isProposal || sectionModulesHaveChildren ? 1 : 0 }
          actions={
            <>
              {
                allowEdition && descriptionMode < 3 && visibleWorkTypes.length === taskWorkTypes.length &&
                <HeaderWorkTypeEditor
                  isLibrary={ isLibrary }
                  section={ section }
                  visibleWorkTypes={ visibleWorkTypes }
                />
              }
            </>
          }
          allowEdition={ showDescriptionCell && allowEdition }
          setWidth={ handleTitleCellRescale }
          setNextCellWidth={ handleDescriptionCellRescale }
          nextCellWidth={ descriptionCellWidth }
          nextCellMaxWidth={ maxDescriptionCellWidth }
        >
        <span className="preset-tableBreakdownHeader pt-module">
          { getFieldName(
            "pt-module",
            isLibrary ? startingLevel : sectionFormat === 1 ? 0 : 1
          ) }
        </span>
        </TitleCell>
        {
          showDescriptionCell &&
          <DescriptionCell
            minWidth={ descriptionCellWidth }
            maxWidth={ maxDescriptionCellWidth }
            left={ titleCellWidth }
            isHeader
            size="xSmall"
            useRescale={ allowEdition }
            setWidth={ handleDescriptionCellRescale }
            actions={
              allowEdition && visibleWorkTypes.length === taskWorkTypes.length &&
              <HeaderWorkTypeEditor
                isLibrary={ isLibrary }
                section={ section }
                visibleWorkTypes={ visibleWorkTypes }
              />
            }
          >
          <span className="preset-tableBreakdownHeader ml-0-5">
            { t("common.desc") }
          </span>
          </DescriptionCell>
        }
        
        <Grid
          className={ classnames(TABLE_DRAGGABLE_CONTAINER, "wt-container") }
          item
          container
          wrap="nowrap"
          alignItems="center"
          style={ { width: "initial" } }
        >
          <SortableList
            list={ visibleWorkTypes }
            group="breakdown-systems"
            onListReorder={ handleWorkTypeReorder }
            displayAsFlexbox
            disabled={ !allowEdition }
          >
            { showBreakdownColumns &&
              visibleWorkTypes?.map(( workType ) => (
                <HeaderWorkType
                  key={ workType.id }
                  workType={ workType }
                  section={ section }
                  allowEdition={ allowEdition }
                  isLibrary={ isLibrary }
                  isSellerOrClient={ isSellerOrClient }
                  visibleWorkTypes={ visibleWorkTypes }
                />
              )) }
          </SortableList>
        </Grid>
        { pricesVisible && (
          <PriceCell wider={ usesTwoValues }>
          <span className="preset-tableBreakdownHeader pt-price">
            { getFieldName("pt-price") +
              (currencyObj?.code ? ` (${ currencyObj.code || "" })` : "") }
          </span>
            { hasChildrenHours && (
              <TooltipIcon
                style={ { right: 0 } }
                className="absolute text-sm"
                icon={ <Warning className="color-warning"/> }
              >
                { t(`alerts.editor.fixed_price_alert`) }
              </TooltipIcon>
            ) }
            { isLibrary && (
              <TooltipIcon
                color="primary"
                style={ { right: 0 } }
                className="absolute text-sm"
                interactive
              >
                { t("views.editor.price_library_info.text") }
                <Link to="/home/settings/project">
                  { t("views.editor.price_library_info.link") }
                </Link>
              </TooltipIcon>
            ) }
            {/*{hasAverageRate(usedWorkTypes) && !isProposal && (*/ }
            {/*  <TooltipIcon*/ }
            {/*    style={{ fontSize: ".9rem" }}*/ }
            {/*    interactive*/ }
            {/*    className="color-warning"*/ }
            {/*  >*/ }
            {/*    {t("views.editor.average_breakdown_rate_info_tooltip")}*/ }
            {/*  </TooltipIcon>*/ }
            {/*)}*/ }
          </PriceCell>
        ) }
      </HeaderRow>
    </>
  );
});

TableHeader.propTypes = {
  section: object.isRequired,
  tableIdentifier: string.isRequired,
  isSellerOrClient: bool,
  isProposal: bool,
  isLibrary: bool,
  useClientActions: bool,
  blockExpansions: bool,
  useExpandCell: bool,
  useDescriptionCell: bool,
  pricesVisible: bool,
  maxTitleCellWidth: number,
  maxDescriptionCellWidth: number,
};
