import { observer } from "mobx-react";
import { Grid } from "@material-ui/core";
import { Checkbox } from "@components";
import { ShareLinkModifiers } from "@utils";
import { sendRecordingPartQuery } from "@query/proposal/saveShareSettingsQuery";
import { useEffect, useState } from "react";
import { useProjectEditorStore } from "@hooks";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";

export const ShareLinkSettings = observer(() => {
  
  const { t } = useTranslation();
  const { projectUuid } = useParams();
  const editorStore = useProjectEditorStore();
  
  const { noTurningOff } = editorStore;
  
  const [isSendingSettings, setSendingSettings] = useState(false);
  
  const updateShareLinkSettings = async () => {
    await sendRecordingPartQuery(projectUuid, editorStore.shareLinkSettings);
    setSendingSettings(false);
  }
  
  const handleAllowTurnOff = () => {
    editorStore.setShareLinkSettings(
      ShareLinkModifiers.NO_TURN_OFF,
      !noTurningOff
    );
    setSendingSettings(true);
  }
  
  useEffect(() => {
    updateShareLinkSettings();
  }, [isSendingSettings]);
  
  return (
    <Grid
      item
      container
      wrap="nowrap"
      alignItems="center"
      className="mb-6"
    >
      <Checkbox
        name="allowTurnOff"
        checked={!noTurningOff}
        label={t("Allow turning off proposal elements")}
        onChange={handleAllowTurnOff}
        disabled={isSendingSettings}
      />
    </Grid>
  );
})